/*********************
TRANSITION
*********************/

/*
USAGE: @include transition(all 0.2s ease-in-out);
*/

@mixin transition($transition...) {
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}