// import mixins

@import "mixins";


// variables

$body-font: 'Droid Serif';
$subtitle-font: 'Droid Serif';
$title-font: 'HalisR-Bold';
// $menu-font: 'Brandon Grotesque BoldX', 'LatoX', 'Bungee';
$main-color: #000;

$dark: #2d2d2d;
$light: #fbfbfb;
$accent: #00dabc;

html, body {
  height: 100%;
  width: 100%;
  // overflow-x: hidden;
}

body {
	background: white;
	// color: #1F1F1F;
	color: $dark;
	// color: #ccc;
	font-size: 22px;
	font-family: $body-font;
	// font-weight: 100;
	// line-height: 1.2em;
	letter-spacing: -0.00em; 
	// font-style: italic;
  // text-align: center;
  margin-bottom: 100vh;
  // overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
    // overflow-y: scroll;
  }

}

p {
  line-height: 1.6em;
  margin: 0!important;
  font-style: italic;
}

b {
  font-weight: 900;
}

del {
	// text-decoration: none;
}

ins {
	text-decoration: none;
}

mark {
  background: none;
  // text-decoration: underline;
  color: inherit;
  font-weight: bold;
  font-style: italic;
  // font-family: $title-font;
  border-bottom: 2px solid $accent;
}

a, .nav-project i, .overlay-trigger {
	opacity: 1;
	color: $accent;
	text-decoration: none;
  position: relative;
  cursor: pointer;
  @include transition(all 0.3s ease-in-out);
}

a:hover {
    opacity: 0.5;
    @include transition(all 0.3s ease-in-out);  
}

.nav-project i, .overlay-trigger {

  &:hover {
    // opacity: 0.5;
    @include transition(all 0.3s ease-in-out);
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}


span.meta {
	font-size: 0.8em;
}

#main {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
  // margin-bottom: 100vh;
}

#content {
	// padding: 200px 100px 30px 100px;	
	// width: 800px;
	margin: 0 auto;
  position: relative;
  background: $light;
}

.home {
	#bg {
		// background: #84BAC6;
	}

	h1 {
		// height: 150px;
	}
}

.work #bg {
	// background: red;
}

h1, h2, h3, h4, h5 {
  font-family: $title-font;
  font-weight: normal;
  font-style: normal; 
  text-align: left;
  margin: 0;
}

h1 {
	line-height: 0.9em;
	font-size: 80px;
	// max-width: 1000px;
	// text-transform: uppercase;	
	font-family: $title-font;
	font-weight: 900;
	font-style: normal;
	letter-spacing: -0.04em;
  text-align: left;
  text-transform: uppercase;
}

h2 {
  line-height: 1.05em;
  font-size: 40px;
}


h3 {
  letter-spacing: 0.02em;
  line-height: 1.3em;
  font-size: 30px;
}

nav {
    margin: 50px 100px;
    position: absolute;
    background: $light;
    z-index: 100;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0;
    font-family: $body-font;
    font-style: normal;
    font-size: 20px;
    font-weight: 300;
    // border-bottom: 1px solid;
    padding: 5px 0 0 0;
    text-align: left;
    font-family: $title-font;

    a {
      font-style: italic;
    }

  h4 {
    display: inline-block;
  }

	div {
    display: inline;
		position: relative;
		text-transform: lowercase;
    font-family: $body-font;
    margin-left: 5px;  
    font-size: 14px;
    top: -1px;

    &:not(:first-of-type) {
      
      &:before {
        content: "/";
        margin-right: 5px;
      }
    }

    .current {
      color: $dark;
      cursor: default;

      &:hover {
        border-bottom: none!important;
      }

      &:before {
        content: '';
      } 
    }

		&.left-align {
			left: 0;
		}

		&.center-align {
			flex: 1;
			text-align: center;
			// font-family: 'Rubik';
			// font-weight: 900;
			text-transform: uppercase;
			font-size: 20px;
		}

		&.right-align {
			right: 0;
		}		
	}
}

/*
 * Keyframes
 */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
	opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes changeColor {
  0% {
    // background: 0;
  }

  100% {
    background: red;
  }
}

/*
 * CSS Page Transitions
 * Don't forget to add vendor prefixes!
 */
.m-scene {
  /** Basic styles for an animated element */
  .scene_element {
  	-webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-transition-timing-function: cubic-bezier(0.895,  0.030, 0.685, 0.220);
    -moz-transition-timing-function: cubic-bezier(0.895,  0.030, 0.685, 0.220);
    transition-timing-function: cubic-bezier(0.895,  0.030, 0.685, 0.220);
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  /** An element that fades in */
  .scene_element--fadein {
    animation: fadeIn 0.25s ease-in;
  }

  /** An element that fades in and slides up */
  .scene_element--fadeinup {
  	-webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    animation-name: fadeInUp;
    // -webkit-animation-duration: 1.5s;
    // -moz-animation-duration: 1.5s;
    // animation-duration: 1.5s;        
  }

  /** An element that fades in and slides from the right */
  .scene_element--fadeinright {
    animation: fadeInRight 1.25s ease-in-out;
  }

  /** An element that fades in and slides from the right */
  .scene_element--fadeinright {
    animation: fadeInRight 1.25s ease-in-out;
  }  

    /** Reverse "exit" animations */
  &.is-exiting {
      .scene_element {
        animation: fadeIn 1s cubic-bezier(0.895,  0.030, 0.685, 0.220);
        animation-direction: alternate-reverse; 
      }
  }

  .delayed {
    animation-delay: 0.5s;
  }  
}

.content-narrow {
  padding: 100px 200px 100px 200px; 
  max-width: 700px; 
  margin: auto;  
}

.subtitle {
  margin-bottom: 80px;
}

.section-short {
  background: $dark;
  color: $light;

  &.quote {
    // font-family: $title-font;
    // font-size: 0.9em;
    background: $light;
    color: $dark;
    // background-image: url(https://cdn.vectorstock.com/i/composite/23,74/pixel-thumb-up-vector-1742374.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% auto;
    // mark {
    //   font-family: inherit;
    //   border-bottom: 3px solid $accent;
    //   font-style: normal;
    //   font-weight: normal;
    // }

  }
}

.section-full {
	height: 100vh;
  min-height: 600px;
  position: relative;
  // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2NkwAP+////nxGXPFgSBLApgEmC5DAUIEtiKECXRFGATRKuAJckWAE+SZACAIKGIAUwJOAdAAAAAElFTkSuQmCC); 
  
  &:nth-child(odd) {
    background-color: lighten($dark,75%);; 
  }

  &:nth-child(odd) {
      // background: #ccc;
      
    p, span, .item-title, .overlay-title {
      // color: white;

    }
  }

  &:first-child .prev-project {
    display: none;
  }

  &:last-child .next-project {
    display: none;
  }  

  // &:before {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   top: 50px;
  //   left: 70px;
  //   width: 50px;
  //   height: 5px;
  //   background: $dark;
  // } 

  //  &:after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 140px;
  //   width: 100%;
  //   max-width: 20%;
  //   height: 2px;
  //   background: $accent;
  // }    

  p {
    // padding: 0 100px;
    // text-align: left;
    width: 50%;
    // max-width: 900px;
  }

  .item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    // background-color: #eee;   
    
    background-attachment: fixed;
    background-repeat: no-repeat;

    background-position: 120% center;
    background-size: 63% auto;
    
    // background-size: 60% auto;
    // background-position: 130% 50%;

    opacity: 1;
    // transition: all 1s ease-in-out 1s;
    // -webkit-transition: all 1s ease-in-out 1s;    
    @include transition(all 1s ease-in-out 1s);
  }

  .item-number {
    font-family: $subtitle-font;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
    // letter-spacing: 0.1em;
    line-height: 0.9em;
    font-size: 300px;
    color: rgba(34, 34, 34, 0.3);
    // z-index: 10;
    position: absolute;
    top: 80px;
    // background: white;
    // padding: 0 20px;
    // margin-left: 10px;
    max-width: 30%;
    text-align: left;
    opacity: 0.5;
    transform: translateX(-40px);
    -webkit-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    // transition: opacity 1s ease-out 0.6s, transform 1s 0.5s;
    @include transition(opacity 1s ease-out 0.6s, transform 1s 0.5s);
  }

  // .next-project, .previous-project  {
  //   position: absolute;
  //   top: 70px;
  //   z-index: 100;
  //   // opacity: 1;
  //   transform: translateY(0px);
  //   transition: opacity 1s ease-out 0.8s, transform 1s 0.7s;    
  // }  

  // .next-project {
  //   left: 70px;
  // }

  // .previous-project {
  //   left: 100px;    
  // }  

  .nav-project  {
    position: relative;
    top: -15px;
    // left: 70px;
    z-index: 100;
    width: 95%;
    text-align: right;
    display: block;
    opacity: 1;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);    
    // transition: opacity 1s ease-out 0.8s, transform 1s 0.7s;
    @include transition(opacity 1s ease-out 0.8s, transform 1s 0.7s);
  }  

  .open-project {
    letter-spacing: 0.1em;
    font-size: 0.8em;
    margin-top: -0.2em;
    display: block;
    text-align: right;
    width: 95%;
    // font-family: $title-font;
    opacity: 1;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);  
    // transition: opacity 1s ease-out 0.8s, transform 1s 0.7s, padding-left 0.5s; 
    @include transition(opacity 1s ease-out 0.8s, transform 1s 0.7s, padding-left 0.5s);

    &:hover {
      padding-left: 10px;
      transition: all 0.5s; 
    }     
  }

  .item-content {
    position: absolute;
    top: 270px;
    // height: 100%;
    // background: white;
    padding: 0 20px;
    margin-left: 50px;
    max-width: 30%;
    z-index: 10;
    text-align: left;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);      
    // transition: opacity 1s ease-out 0.8s, transform 1s 0.7s;
    @include transition(opacity 1s ease-out 0.8s, transform 1s 0.7s);

    i {
      // font-size: 40px;
    }

  }

  .item-title {
    font-family: $title-font;
    font-weight: 900;
    text-transform: uppercase;
    // letter-spacing: 0.1em;
    line-height: 0.9em;
    font-size: 50px;
    // z-index: 10;
    // position: absolute;
    // top: 300px;
    // background: white;
    // padding: 0 20px;
    // margin-left: 50px;
    // max-width: 30%;
    // text-align: left;
    // opacity: 1;
    // transform: translateY(0px);
    // transition: opacity 1s ease-out 0.1s, transform 1s;

    &.overlay-trigger {
      bottom: 100px;
      top: auto;
    }
  }

  .item-meta {
    position: relative;
    font-size: 30px;
    z-index: 11;
    margin-bottom: 10px;
    // position: absolute;
    // top: 400px;
    // background: white;
    // padding: 0 20px;
    // margin-left: 50px;
    // max-width: 30%;

  }

  p {
    width: 100%;
    font-size: 20px;
    line-height: 1.3em;
  }   

  /* When overlay is active */
  &.show-overlay {

    height: auto;

    .overlay {
      left: 0px;
      padding-bottom: 100px;
      opacity: 1;
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);        
      // -webkit-transition: opacity 1s ease-out 0.5s, transform 1s 0.4s;
      // transition: opacity 1s ease-out 0.5s, transform 1s 0.4s;
      @include transition(opacity 1s ease-out 0.5s, transform 1s 0.4s);
    }
  }  
  
  &.show-overlay, 
  &.not-in-view {    
    .nav-project {
      opacity: 0;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -ms-transform: translateX(-30px);  
      // transition: opacity 1s ease-out 0.2s, transform 1s;
      @include transition(opacity 1s ease-out 0.2s, transform 1s); 
    }  

    .open-project {
      opacity: 0;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      // transition: opacity 1s ease-out 0.2s, transform 1s;
      @include transition(opacity 1s ease-out 0.2s, transform 1s); 
    }     

    .item-content {
      opacity: 0;
      transform: translateX(-30px);
      -webkit-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      // transition: opacity 1s ease-out 0.2s, transform 1s;
      @include transition(opacity 1s ease-out 0.2s, transform 1s);
    } 

    .item-number {
      position: absolute;
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      // transition: opacity 1s ease-out 0.1s, transform 1s;
      @include transition(opacity 1s ease-out 0.1s, transform 1s);      
    }  
      
    .item-bg {
      // opacity: 0;
      // background-position: 100% center;
      // transition: all 1s ease-out;
      @include transition(all 1s ease-out); 
    }

  }

  &.show-overlay {
  
    .item-bg {
      opacity: 0!important;
    }

  } 

  &.not-in-view {
  
    .item-bg {
      opacity: 1;
    }

  } 

}

/* Overlay styles */
.overlay {
  position: relative;
  // top: 30px;
  // left: -100%;
  // right: 0px;
  // bottom: 0px;
  width: 100%;
  height: 100%;
  // display: none;
  overflow: hidden;
  opacity: 0;
  // z-index: 100;
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  // -webkit-transition: opacity 1s ease-out 0.2s, transform 1s;
  // transition: opacity 1s ease-out 0.2s, transform 1s;
  @include transition(opacity 1s ease-out 0.2s, transform 1s);
  // overflow: auto;

  // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANklEQVQoU2NkwAPOnDnznxGXPEjSxMSEEasCmCRIM4YCZEkMBeiSKAqwScIV4JIEK8AnCVIAAOXhH2wnfjlhAAAAAElFTkSuQmCC);
  // background-attachment: fixed;
  // background-repeat: repeat;
  // background-position: top;
  // background-size: contain;

    &:nth-child(odd) {
    // background-color: #eee;   
  }
}

.overlay-trigger {
  cursor: pointer;
  // color: $accent;

  &:hover {
    // color: black;
  }
}

.overlay .overlay-close {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 50px;
  // width: 32px;
  // height: 32px;
  // opacity: 1;
}

.overlay .overlay-close:hover {
  // color: #000;
}
// .overlay .overlay-close:before, .overlay .overlay-close:after {
//   position: absolute;
//   left: 15px;
//   content: ' ';
//   height: 33px;
//   width: 2px;
//   background-color: #333;
// }
// .overlay .overlay-close:before {
//   transform: rotate(45deg);
// }
// .overlay .overlay-close:after {
//   transform: rotate(-45deg);
// }

.overlay .overlay-content {
  padding: 0 40px; 
  max-width: 700px; 
  margin: auto;
  text-align: left
}

.overlay .overlay-title {
  padding: 120px 0 20px 0; 
  // max-width: 700px; 
  margin: auto; 
  text-align: left;
  font-size: 60px; 
} 

.overlay .overlay-subtitle {
  font-family: $title-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  display: block;
  width: 100%;
  margin-top: 20px;
  // text-decoration: underline;
  // text-transform: uppercase;
} 

.overlay img {
  width: 100%;
  margin: 30px auto;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
}



footer {
    // z-index: -1;
    width: 100%;
    // height: 100%;
    // left: 0;
    // bottom: 0;
    // margin: 0;
    // padding: 0; 
    // border-bottom: 50px solid $accent; 

    .section-short {
      background: $dark!important;
      color: #fff;
    
      &#copyright {
        background: $accent!important;        

        .content-narrow {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        p {
          font-style: normal;
          font-family: $title-font;
          // text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
}


@media only screen and (max-width : 1060px) {

  nav {
    margin: 50px 100px;
  }

  .content-narrow {
    padding: 100px 100px;
  }

 .section-full {
    min-height: 600px;
  
    .item-bg {
      background-attachment: scroll;
      background-size: 70% auto;
      background-position: 130% 50%;
    }

    .nav-project {
      text-align: left;
    }

    .item-meta {
      display: none;
    }

    .item-content {
      margin-left: 40px;
      // top: 280px;
      padding: 0;
      max-width: 30%;
      width: 90%;
    }

    .item-title {
      max-width: 400px;
      font-size: 40px;
    }

    .open-project {
      text-align: left;
      width: 100%;
    }

    &.show-overlay .overlay {
      z-index: 20;
    } 

    &.show-overlay .nav-project {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px); 
    }

    &.show-overlay .nav-project {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px); 
    }    
  }  

} // end 1060px breakpoint

@media only screen and (max-width : 860px) {

  h1 {
    font-size: 64px;
  }

  nav {
    margin: 50px 80px;
    // display: block;
    width: 100%;  
  }  

  .content-narrow {
    padding: 80px 80px;
  }

  .section-full {

  }

} // end 860px breakpoint

@media only screen and (max-width : 700px) {

  nav {
    // margin: 40px 40px;
    // display: block;
    width: 100%;

    h4 {
      line-height: 0;
      margin-bottom: 5px;
      display: block;
      width: 100%;
    }

    div {
      // display: block;
      // margin-left: 0;
    }

    div:first-of-type {
      // content: '';
      margin-left: 0;    
    }

    div:not(:first-child):before {
      // content: '';
      // margin-right: 0;    
    }
  }

  .section-full {
    min-height: 740px; 

    .item-title {
      max-width: 90%;
    }

    .item-content {
      top: 80px;
      max-width: 90%;
    }   

    .item-bg {
      background-attachment: scroll;
      background-size: 90% auto;
      background-position: 50% 80%;
    }    
  }
} // end 700px breakpoint 


@media only screen and (max-width : 520px) {

  h1 {
    font-size: 58px;
  }

  nav {
    margin: 50px 30px;

    h4 {
      margin-bottom: 10px;
    }

    div {
      display: block;
      width: 100%;
      margin-left: 0;
    }

    div:not(:first-child):before {
      content: '';
      margin-right: 0;
    }
  }

  .content-narrow {
    padding: 80px 30px;
  }  
  
  .section-full {
    min-height: 600px;    

    .item-bg {
      background-attachment: scroll;
      background-size: 90% auto;
      background-position: 50% 80%;
    }

  .overlay .overlay-title {
    font-size: 50px; 
  }     
  }
} // end 520px breakpoint


